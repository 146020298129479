/*
 * @Author: joinin join.in@qq.com
 * @Date: 2024-05-14 23:20:39
 * @LastEditors: joinin join.in@qq.com
 * @LastEditTime: 2024-05-14 23:37:22
 * @FilePath: \joinin-chat\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index.vue'
// import Layout from '@/layout'
import { getToken } from '@/utils/cookie'
Vue.use(VueRouter)

const routes = [
  {
    path: '/redirect',
    // component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect')
      }
    ]
  },
  // {
  //   path: '/test',
  //   component: () => import('@/views/test'),
  //   hidden: true,
  //   name: 'Test'
  // },
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
    name: 'Login'
  },
  {
    path: '',
    component: Home,
    children: [
      {
        path: '/',
        component: () => import('@/views/index'),
        name: 'Index',
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  // base: '/',
  scrollBehavior: () => ({ y: 0 }),
  routes
})
//路由拦截
router.beforeEach((to, from, next) => {
  //验证浏览器本地存储是否有token（或从vuex、Cookie、localStorage中获取，依据自己的存储），用于判断是否登录
  if (to.name !='Login' && !getToken()) {//没有token，表示未登录，则重定向到login
      next({
          //这里是你登录页面的路由名称，或直接使用 path:'/login'
          // name: 'Login',
          // query: { redirect: to.fullPath }
          path:'/login'
      })
  } else {
      next()
  }
})

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
  VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default router
