import request from '@/utils/request'

// 登录方法
export function login(data) {
  return request({
    url: '/auth/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}

// 退出方法
export function loginOut() {
  return request({
    url: '/auth/loginOut',
    method: 'get'
  })
}

export function getCaptcha() {
  return request({
    url: '/auth/getCaptcha',
    headers: {
      isToken: false
    },
    method: 'get'
  })
}