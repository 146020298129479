<template>
  <el-container style="height: 100vh;">
    <el-aside v-if="!isMobile" width="200px" style="text-align: center; border-right: solid 1px #e6e6e6 ;">
      <div v-for="(item,index) in msgList" :key="index" style="margin-top: 20px;">
        <el-button v-if="index == activeMsg" type="primary" :disabled="!receiveComplete" style=" width: 90%;" @click="onActive(index)">会话 {{ index + 1 }}</el-button>
        <el-button v-else type="info" :disabled="!receiveComplete" style=" width: 90%;" @click="onActive(index)">Chat {{ index + 1 }}</el-button>
      </div>
      <el-button type="success" :disabled="!receiveComplete" style="margin-top: 20px;" @click="addMsg">新增会话</el-button>
    </el-aside>
    <el-container>
      <div v-if="!receiveComplete" style="position: fixed; top: 40%; width: 100%; z-index: 999; text-align: center;">
      <div class="animation">
        <span class="girl"></span>
        <div class="boys">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <el-button size="small" type="info" style="margin-top: 5px;" @click="cancleLoding">关闭抖动</el-button>
    </div>
      <el-header style="min-height: 60px; height: auto;">
        <el-menu :default-active="activeValue" mode="horizontal" style="width: 100%;">
          <el-menu-item index="0">
            <a href="#"><i class="el-icon-menu"></i></a>
          </el-menu-item>
          <el-menu-item index="1">ChatGPT</el-menu-item>
          <el-menu-item index="2">图片生成</el-menu-item>
          <el-submenu index="2-4" style="float: right;">
            <template slot="title">User</template>
            <el-menu-item index="2-4-1" @click="logout">退出</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-header>
      <el-main>
        <el-scrollbar v-if="isMobile" ref="myScrollbar" style="height: 100%;">
          <div  v-for="(data) in msgList[this.activeMsg]" :key="data.id">
              <!-- 发送人 -->
              <el-row v-if="data.request" style="margin: 10px 0;">
                <el-col :span="2" style="text-align: right; margin-right: 10px;margin-top:5px;"> 
                  <el-avatar :size="avatarSize" shape="square" style="background-color:#E6A23C;">User</el-avatar> 
                </el-col>
                <el-col :span="21">
                  <v-md-preview :text="data.content" style="display: inline-block;"></v-md-preview>
                </el-col>
              </el-row>
              <el-row v-else-if="data.type ==='chat'" style="margin: 10px 0;">
                <el-col :span="2" style="text-align: right; margin-right: 10px;margin-top:5px;">
                  <el-avatar :size="avatarSize" shape="square" style="background-color: #303133;">Ai</el-avatar>
                </el-col>
                <el-col :span="21">
                  <v-md-preview :text="data.content" style="display: inline-block;"></v-md-preview>
                </el-col>
              </el-row>
           </div>
        </el-scrollbar>
        <el-scrollbar v-else ref="myScrollbar" style="height: 100%;">
          <div v-for="(data) in msgList[this.activeMsg]" :key="data.id">
              <!-- 发送人 -->
              <el-row v-if="data.request" style="margin: 10px 0;">
                <el-col :span="1" style="text-align: right; margin-right: 10px;margin-top:5px;"> 
                  <el-avatar :size="avatarSize" shape="square" style="background-color:#E6A23C;">User</el-avatar> 
                </el-col>
                <el-col :span="21">
                  <v-md-preview :text="data.content" style="display: inline-block;"></v-md-preview>
                </el-col>
              </el-row>
              <el-row v-else-if="data.type ==='chat'" style="margin: 10px 0;">
                <el-col :span="1" style="text-align: right; margin-right: 10px;margin-top:5px;">
                  <el-avatar :size="avatarSize" shape="square" style="background-color: #303133;">Ai</el-avatar>
                </el-col>
                <el-col :span="21">
                  <v-md-preview :text="data.content" style="display: inline-block;"></v-md-preview>
                </el-col>
              </el-row>
           </div>
        </el-scrollbar>
      </el-main>
      <el-footer height="100px" style="text-align: center;background-color: #F2F6FC;">
        <el-row style="margin: 10px 0;">
          <el-col :span="22" :push="1">
            <el-input clearable placeholder="请输入内容 ctrl+enter 快捷发送" v-model="textarea" prefix-icon="el-icon-edit-outline" @keydown.ctrl.enter.native="socketSendmsg()">
              <el-button slot="append" type="primary" :disabled="!receiveComplete" icon="el-icon-s-promotion" @click="socketSendmsg()"></el-button>
            </el-input>
          </el-col>
        </el-row>
          <el-link style="font-size: 10px;" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备19010725号-1</el-link>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import {v4 as uuidv4} from 'uuid'
import {getCurDatetime } from '@/utils/dateutils'
import { getToken,removeToken } from '@/utils/cookie'
import { loginOut } from '@/api/login';
import logoImg from '@/assets/logo.png'
export default {
  name: 'Home',
  data() {
    return {
      list: ['你好','hello'],
      activeValue: "1",
      logoUrl: logoImg,
      id: '',
      textarea: '',
      socket_conn: false,
      btnLoad: false,
      msgList: [],
      activeMsg: 0,
      contentList: [],
      messages: [],
      images: [],
      chatId: '',
      stop: true,
      receiveComplete: true,
      message: '',
      avatarSize: 'large',
      isCollapse: true,
      isMobile: false,
      tabIndex: 'chat',
      form: {
          prompt: '',
          size: '256x256',
          num: ''
      },
      rules: {
        prompt: [
            { required: true, message: '请输入图片描述', trigger: 'blur' }
          ]
      },
    }
  },
  watch: {
    message: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
        let el = this.$refs.myScrollbar.$el;
        el.style.maxHeight =el.scrollHeight +'px'
      });
  },
  created() {
    this.msgList.push(this.messages)
    this.connected()
    this.getDevice()
    // this.showNotice()
  },
  methods: {
    getDevice() {
      let userAgent = window.navigator.userAgent
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)){
        // 手机和平板设备
        console.log('手机和平板设备')
        this.isMobile = true
        this.avatarSize = 'medium'
      } else {
        // PC 设备
        console.log(' PC 设备')
        this.isMobile = false
        this.avatarSize ='large'
      }
    },
    showNotice() {
      this.$notify.info({
        title: 'API说明',
        message: '基于OpenAi官方的GPT3.5版本API实现。'
      });

      this.$notify.info({
        title: '问答说明',
        message: '由于免费版本额度有限，故Guest用户每天做了100次的问答限制。'
      });
    },
    getRandom(num) {
      return Math.round(Math.random() * num)
    },
    initContents(){
      const contents = []
      contents.push({'role': 'system', 'content': 'You are a helpful assistant.'})
      this.contentList.push(contents)
    },
    init() {
      this.initContents()
      let text = this.list[this.getRandom(2)]
      this.contentList[this.activeMsg].push({'role': 'user', 'content': text})
      let data = {
        id:uuidv4(),
        time: getCurDatetime(),
        content: this.contentList[this.activeMsg],
        request: true,
        token:getToken()
      }
      this.$socket.emit('joinin_message',JSON.stringify(data))
      this.message = text
      this.stop = false
      this.textarea = ''
    },
    cancleLoding() {
      this.receiveComplete = true
      // 暂不取消接口
    },
    addMsg() {
      const messages = []
      this.msgList.push(messages)
      this.activeMsg = this.msgList.length - 1
      this.initContents()
    },
    onActive(i) {
      this.activeMsg = i
    },
    handleClick() {
      this.$refs.input.focus();
    },
    connected() {
      this.$socket.open() // 开始连接socket
      this.init()
    },
    disconnected() {
      this.$socket.close()
    },
    socketSendmsg() {
      if (this.receiveComplete) {
        // 检查socke 是否已连接
        if (!this.socket_conn) {
          this.$message({
            message: '当前未连接，请刷新重试',
            type: 'warning'
          });
        } else if (this.tabIndex === 'chat'){
          if (this.textarea) {
            let data = {
              id:uuidv4(),
              time: getCurDatetime(),
              content: this.textarea,
              type: 'chat',
              request: true,
              token:getToken()
            }
            this.msgList[this.activeMsg].push(data)
            if(this.contentList[this.activeMsg].length > 21) {
              this.initContents()
            }
            this.contentList[this.activeMsg].push({'role': 'user', 'content': this.textarea})
            let reqData = {
              id:data.id,
              time:data.time,
              content:this.contentList[this.activeMsg],
              type:data.type,
              request:data.request,
              token:data.token
            }
            if (reqData.content.length > 1) {
              this.receiveComplete = false
              this.$socket.emit('joinin_message',JSON.stringify(reqData))
            }
            this.message = this.textarea
            this.stop = false
            this.textarea = ''
          }
        }else if (this.tabIndex === 'img') {
          this.$refs['chatForm'].validate((valid) => {
            let content = this.isMobile ? this.textarea : this.form.prompt 
            if (valid && content) {
              let content = this.isMobile ? this.textarea : this.form.prompt 
              let data = {
                id:uuidv4(),
                time: getCurDatetime(),
                content: content,
                num: this.form.num,
                size: this.form.size,
                type: 'img',
                request: true,
                token:getToken()
              }
              this.msgList[this.activeMsg].messages.push(data)
              this.message = data.content
              this.textarea =''
              this.stop = false
              this.$socket.emit('joinin_img',JSON.stringify(data))
              if (this.isMobile) {
                this.changeChat()
              }
            } else {
              return false;
            }
          });
        } 
      }
    },
    logout() {
      this.$confirm('确定退出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        loginOut().then( res => {
          this.$message({
            type: 'success',
            message: res.data
          });
          this.goLogin()
        })
      }).catch(() => {
        console.log('取消退出')      
      });
    },
    goLogin() {
      removeToken()
      this.$router.push('/login')
    },
    clearAll() {
      this.msgList = []
      this.images = []
      this.message = 'clear'
      this.contentList = []
      this.textarea =''
      this.content =''
    },
    scrollToBottom() {
      let el = this.$refs.myScrollbar.$el.querySelector('.el-scrollbar__wrap')
      el.scrollTop = el.scrollHeight;
    }
  },
  sockets: {
    connecting() {
      console.log('正在连接')
      this.btnLoad = true
    },
    disconnect() {
      console.log('连接断开')
      this.socket_conn =false
      this.btnLoad = false
    },
    connect_failed() {
      console.log('连接失败')
      this.socket_conn = false
      this.btnLoad = false
    },
    connect() {
      console.log('连接成功')
      // this.getConnectId()
      this.socket_conn = true
      this.btnLoad = false
      console.log(getCurDatetime())
    },
    joinin_message(data) {
      let vm = this
      let res = JSON.parse(data)
      if(res.code === 0) {
        this.receiveComplete = true
        this.$notify.error({
          title: '提示',
          message: res.finish_reason + ',返回登录',
          duration: 3000
        });
        setTimeout(() => {
          vm.goLogin()
        }, 3000);
      } else if (res.code != 200) {
        this.receiveComplete = true
        this.$notify({
          title: '提示',
          message: res.msg || res.finish_reason,
          duration: 3000,
          type: 'warning'
        });
      }
      // 发送人的请求
      if (!res.request) {
        // 服务器请求返回
        if(res.type === 'chat') {
          // 如果chatId为空，且role有值，说明第一条，给定chatId值
          if (this.chatId === '' && res.role) {
            this.chatId = res.id
            this.message = ''
          }
          // 如果chatId有值且跟后面返回的值一样，那说明是同一条未结束数据
          else if (this.chatId && this.chatId === res.id ) {
            let len = this.msgList[this.activeMsg].length
            if (len > 0) {
              if (this.msgList[this.activeMsg][len - 1].id != res.id) {
                this.msgList[this.activeMsg].push(res)
              } else {
                this.msgList[this.activeMsg][len - 1].content += res.content
              }
              this.message = this.msgList[this.activeMsg][len - 1].content
            } else {
              this.msgList[this.activeMsg].push(res)
            }
          }
          if(res.finish_reason === 'stop') {
            this.contentList[this.activeMsg].push({'role': 'assistant', 'content': this.msgList[this.activeMsg][this.msgList[this.activeMsg].length - 1].content})
            this.stop = true
            this.chatId =''
            this.message =''
            this.receiveComplete = true
          }
        } else if (res.type === 'img') {
          console.log(res)
        }
      }
    },
    joinin_img(data) {
      let vm = this
      let res = JSON.parse(data)
      if(res.code === 0) {
        this.receiveComplete = true
        this.$notify.error({
          title: '提示',
          message: res.finish_reason,
          duration: 3000
        });
        setTimeout(() => {
          vm.goLogin()
        }, 3000);
      } else if (res.code != 200) {
        this.receiveComplete = true
        this.$notify({
          title: '提示',
          message: res.msg || res.finish_reason,
          duration: 3000,
          type: 'warning'
        });
      }
      const images =[]
      for (var i = 0; i < res.list.length; i++) {
        images.push(res.list[i].url)
      }
      res.images = images
      this.msgList[this.activeMsg].push(res)
      this.message = res.finish_reason
    }
  },
  destroyed() {
    this.disconnected()
  }
}
</script>
<style>
  @import '@/assets/css/index.css';
  .el-input .el-input-group__append button.el-button {
    background-color: #409EFF;
    color: #fff;
  }
  .v-md-editor-preview .vuepress-markdown-body:not(.custom) {
      padding: 0.8rem;
    }
  .v-md-editor-preview .vuepress-markdown-body {
    background-color: #F2F6FC;
    color: #303133;
  }
  .el-scrollbar__wrap {
    overflow: auto;
  }
</style>