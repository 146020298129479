import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
// emoji
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';

// 快速复制代码
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
// 显示代码行数
// import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
// 代码高亮
import Prism from 'prismjs';
import 'prismjs/components/prism-json';
VMdPreview.use(vuepressTheme, {
  Prism,
});
VMdPreview.use(createEmojiPlugin())
VMdPreview.use(createCopyCodePlugin())
// VMdPreview.use(createLineNumbertPlugin())
Vue.use(VMdPreview);
Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(
  new VueSocketIO({
    debug: false,//开启调试模式
    connection: SocketIO.connect("/", {
      // transports: ["websocket"],//默认使用的请求方式
      autoConnect: false,//是否自动连接 
    }),
    // extraHeaders: { 'Access-Control-Allow-Origin': '*' }
  })
)

Vue.directive('scroll-bottom', {
  inserted: (el, binding) => {
    const f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  },
  unbind: function(el) {
    window.removeEventListener('scroll', el.scrollEvent)
  }
})

// 定义指令
Vue.directive('draggable', {
  bind: function(el, binding) {
    var startX, startY, initX = binding.value.x, initY = binding.value.y;
    let clientHeight = document.documentElement.clientHeight
    let clientWidth = document.documentElement.clientWidth
    let width = parseInt(el.style.right) + initX
    let height = parseInt(el.style.bottom) + initY
    initX = clientWidth - width
    initY = clientHeight - height
    function handleMousedown(e) {
      startX = e.pageX;
      startY = e.pageY;
      el.style.position = 'absolute';
      el.style.left = initX + 'px';
      el.style.top = initY + 'px';
      document.addEventListener('mousemove', handleMousemove);
      document.addEventListener('mouseup', handleMouseup);
    }

    function handleMousemove(e) {
      var dx = e.pageX - startX;
      var dy = e.pageY - startY;
      el.style.left = initX + dx + 'px';
      el.style.top = initY + dy + 'px';
    }

    function handleMouseup() {
      document.removeEventListener('mousemove', handleMousemove);
      document.removeEventListener('mouseup', handleMouseup);
      // 保存位置信息
      // binding.value.x = parseInt(el.style.left);
      // binding.value.y = parseInt(el.style.top);
      initX = parseInt(el.style.left);
      initY = parseInt(el.style.top);
    }

    el.addEventListener('mousedown', handleMousedown);
    el.addEventListener('touchstart', handleMousedown);
    el.addEventListener('touchmove', handleMousemove);
    el.addEventListener('touchend', handleMouseup);
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
