

export function getToken(){
    let token =localStorage.getItem("joinchat-token")

    return token
}

export function removeToken(){

    localStorage.removeItem("joinchat-token")
}